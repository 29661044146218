import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useCookies } from "react-cookie";

import { Header } from "../components";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import { Years } from "../data/list"

const Home = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const [cookies] = useCookies()
  const [dataValue, setDataValue] = useState([])
  const [monthValue, setMonthValue] = useState([])
  const [yearValue, setYearValue] = useState("2023")

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Energy Consumption',
      },
    },
  };
  
  const labels = monthValue && monthValue;
  
  const data = {
    labels,
    datasets: [
      {
        label: yearValue,
        data: dataValue && dataValue,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };

  useEffect(() => {
    if (cookies['__info'].role === 2) {
      showEnergyConsumptionList(yearValue)
    }
  }, [])

  const showEnergyConsumptionList = (value) => {
      api.get('get/electric-consumption/' + cookies['__info'].office.id + '/' + value)
      .then(res => {
        setDataValue(res.data.data)
        setMonthValue(res.data.month)
      })
      .catch(err => {
          console.log(err)
      })
  }

  const onHandleChange = (e) => {
    const {value} = e.target

    setYearValue(value)

    if (cookies['__info'].role === 2) {
      showEnergyConsumptionList(value)
    }
  }

  return (
    <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Dashboard" title="Home" />
      <div className="flex flex-row space-x-1 mb-2 justify-center">
        <select id="year" name="year" value={yearValue} onChange={onHandleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value="0" disabled>Select a year</option>
            {
                Years.map((result, index) => {
                    return (
                        <option value={result} key={index}> {result} </option>
                    )
                })
            }
        </select>
      </div>

      <Line options={options} data={data} />
    </div>  
  )
}

export default Home;
