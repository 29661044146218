import React from 'react'
import icancel from "../data/cancel.png";

import { MonthsName } from "../data/list"

const ModalPage = props => {

    const {
        energySavingMeasureQuestion,
        emsRow,
        emsCol,
        esmData,
        onHandleChange,
        onCancel,
        month,
        year
    } = props
    
    return (
        <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                <div className="">
                    <div className="text-center p-4 flex-auto justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-11 h-6 -m-1 flex items-center text-blue-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        
                        <h2 className="text-base font-bold mt-4"> { MonthsName[month-1].name + " " + emsCol + ", " + year } </h2>
                        <h2 className="text-xl font-bold mt-2"> { energySavingMeasureQuestion[emsRow-1].name } </h2>
                    </div>
                    <div className="flex text-center p-1 mb-5 flex-auto justify-center">
                        <div className="flex items-center pr-2">
                            <input id="yes" type="radio" value="1" checked={esmData === 1 && "checked"} onChange={onHandleChange} name="data-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label htmlFor="yes" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> &nbsp; Yes</label>
                        </div>
                        <div className="flex items-center pl-2">
                            <input id="no" type="radio" value="0" checked={esmData === 0 && "checked"} onChange={onHandleChange} name="data-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label htmlFor="no" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> &nbsp; No</label>
                        </div>
                    </div>
                    <div className="p-2 text-center space-x-4 md:block">
                        <button name="cancel" id="cancel" onClick={onCancel} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-7" src={icancel} alt=""/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalPage