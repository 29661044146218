import React, { useState, useEffect } from "react";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { FaCheck } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { useCookies } from "react-cookie";

import axios from 'axios'

import { Query } from '@syncfusion/ej2-data';
import { Header } from "../components";
import ModalPage from "./ModalPage";
import PopMessage from "./PopMessage";
import ConfirmModal from "./ConfirmModal";

import { energySavingMeasureQuestion, MonthsName, Years } from "../data/list"

const EnergySavingMeasure = () => {

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [cookies] = useCookies()
    const [officeList, setOfficeState] = useState([])
    const [isModalShow, setIsModalShow] = useState(false)
    const [isMessageShow, setIsMessageShow] = useState(false)
    const [isInitial, setIsInitial] = useState(false)
    const [emsRow, setEmsRow] = useState()
    const [emsCol, setEmsCol] = useState()
    const [initialCol, setInitialCol] = useState()
    const [popUpMsg, setPopUpMsg] = useState("Record has been retrieved.")

    const [esmData, setEsmData] = useState({
        office_id: cookies['__info'].role === 2 ? cookies['__info'].id : '',
        month: '',
        year: '',
        day: [],
        day_temp: '',
        row: '',
        col: '',
        total_r: [],
        total_d: []
    })

    const office_fields = { text: 'name', value: 'id' };

    useEffect(() => {
        showOfficeList()
        setEsmDataDayDefault()
    }, [])

    const showOfficeList = () => {
        api.get('get/office/' + cookies['__info'].campus_id)
        .then(res => {
            setOfficeState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }
   
    const AddDataEntry = (row, col) => () => {

        if (esmData.office_id && esmData.month && esmData.year) {
            setIsModalShow(true)
        
            setEmsRow(row)
            setEmsCol(col)
        }
    }

    const onHandleChange = (e) => {
        const {name, value} = e.target

        if (name === 'data-radio') {
            esmData.day[emsRow-1][emsCol-1] = parseInt(value)
            esmData.day_temp = value
            esmData.row = emsRow
            esmData.col = emsCol

            saveNewEnergySavingMeasure(esmData)
            setPopUpMsg("Record has been updated.")
        } else {          
            if (name === 'office_id')
                esmData.office_id = value
            else if(name === 'month')
                esmData.month = value
            else if(name === 'year')
                esmData.year = value

            getEnergySavingMeasureList({office_id: esmData.office_id, month: esmData.month, year: esmData.year})
            setPopUpMsg("Record has been retrieved.")
        }
        
        setIsModalShow(false)
    }
    
    const onPopUpMessageClose = () => {
        setIsMessageShow(false)
    }
 
    const getEnergySavingMeasureList = async (params) => {
        let res = await api.post('post/get-esm', {params})

        if (res.status === 200) {
            if (res.status === 200) {
                if (res.data[0]) {
                    res.data[0].emsdata.forEach(function(result, index) {
                        esmData.day[index][0] = result.day_1 !== null ? result.day_1 : ''
                        esmData.day[index][1] = result.day_2 !== null ? result.day_2 : ''
                        esmData.day[index][2] = result.day_3 !== null ? result.day_3 : ''
                        esmData.day[index][3] = result.day_4 !== null ? result.day_4 : ''
                        esmData.day[index][4] = result.day_5 !== null ? result.day_5 : ''
                        esmData.day[index][5] = result.day_6 !== null ? result.day_6 : ''
                        esmData.day[index][6] = result.day_7 !== null ? result.day_7 : ''
                        esmData.day[index][7] = result.day_8 !== null ? result.day_8 : ''
                        esmData.day[index][8] = result.day_9 !== null ? result.day_9 : ''
                        esmData.day[index][9] = result.day_10 !== null ? result.day_10 : ''
                        esmData.day[index][10] = result.day_11 !== null ? result.day_11 : ''
                        esmData.day[index][11] = result.day_12 !== null ? result.day_12 : ''
                        esmData.day[index][12] = result.day_13 !== null ? result.day_13 : ''
                        esmData.day[index][13] = result.day_14 !== null ? result.day_14 : ''
                        esmData.day[index][14] = result.day_15 !== null ? result.day_15 : ''
                        esmData.day[index][15] = result.day_16 !== null ? result.day_16 : ''
                        esmData.day[index][16] = result.day_17 !== null ? result.day_17 : ''
                        esmData.day[index][17] = result.day_18 !== null ? result.day_18 : ''
                        esmData.day[index][18] = result.day_19 !== null ? result.day_19 : ''
                        esmData.day[index][19] = result.day_20 !== null ? result.day_20 : ''
                        esmData.day[index][20] = result.day_21 !== null ? result.day_21 : ''
                        esmData.day[index][21] = result.day_22 !== null ? result.day_22 : ''
                        esmData.day[index][22] = result.day_23 !== null ? result.day_23 : ''
                        esmData.day[index][23] = result.day_24 !== null ? result.day_24 : ''
                        esmData.day[index][24] = result.day_25 !== null ? result.day_25 : ''
                        esmData.day[index][25] = result.day_26 !== null ? result.day_26 : ''
                        esmData.day[index][26] = result.day_27 !== null ? result.day_27 : ''
                        esmData.day[index][27] = result.day_28 !== null ? result.day_28 : ''
                        esmData.day[index][28] = result.day_29 !== null ? result.day_29 : ''
                        esmData.day[index][29] = result.day_30 !== null ? result.day_30 : ''
                        esmData.day[index][30] = result.day_31 !== null ? result.day_31 : ''
                        
                        const total = computeTotal(result)
                        const total_right = total.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                        esmData.total_r[index] = total_right

                        //computeTotalDown(total, index)
                    })

                    setIsMessageShow(true)
                } else {
                    setEsmDataDayDefault()
                }
            } else {
                alert("Error while retrieving records, Please try again.")
            }
        }
    }

    const computeTotalDown = (result, index) => {
        if (index == 0) {
            esmData.total_d[index] = result
        } else {
            if (Object.keys(result).length) {
                for (let i = 0; i < Object.keys(result).length-1; i++) {
                    esmData.total_d[index][i] = esmData.total_d[index-1][i] + result[i]
                } 
            } 
        }
    }

    //console.log(esmData.total_d)

    const computeTotal = (result) => {
        const day_value = [
            result.day_1 !== null ? result.day_1 : 0,  
            result.day_2 !== null ? result.day_2 : 0,
            result.day_3 !== null ? result.day_3 : 0, 
            result.day_4 !== null ? result.day_4 : 0,
            result.day_5 !== null ? result.day_5 : 0,
            result.day_6 !== null ? result.day_6 : 0,
            result.day_7 !== null ? result.day_7 : 0,
            result.day_8 !== null ? result.day_8 : 0,
            result.day_9 !== null ? result.day_9 : 0,
            result.day_10 !== null ? result.day_10 : 0,
            result.day_11 !== null ? result.day_11 : 0,
            result.day_12 !== null ? result.day_12 : 0,
            result.day_13 !== null ? result.day_13 : 0,
            result.day_14 !== null ? result.day_14 : 0,
            result.day_15 !== null ? result.day_15 : 0,
            result.day_16 !== null ? result.day_16 : 0,
            result.day_17 !== null ? result.day_17 : 0,
            result.day_18 !== null ? result.day_18 : 0,
            result.day_19 !== null ? result.day_19 : 0,
            result.day_20 !== null ? result.day_20 : 0,
            result.day_21 !== null ? result.day_21 : 0,
            result.day_22 !== null ? result.day_22 : 0,
            result.day_23 !== null ? result.day_23 : 0,
            result.day_24 !== null ? result.day_24 : 0,
            result.day_25 !== null ? result.day_25 : 0,
            result.day_26 !== null ? result.day_26 : 0,
            result.day_27 !== null ? result.day_27 : 0,
            result.day_28 !== null ? result.day_28 : 0,
            result.day_29 !== null ? result.day_29 : 0,
            result.day_30 !== null ? result.day_30 : 0,
            result.day_31 !== null ? result.day_31 : 0,
        ]

        return day_value
    }

    const setEsmDataDayDefault = () => {
        setEsmData({...esmData, 
            day: [
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
                ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
            ], total_r :[]
        })
    }

    const saveNewEnergySavingMeasure = async (params) => {
        let res = await api.post('post/add-esm', {params})

        if (res.status === 200) {
            if (res.data.is_success === 0) {
                alert("Error while updating record, Please try again")
            }

            getEnergySavingMeasureList({office_id: esmData.office_id, month: esmData.month, year: esmData.year})
        }
    }

    const onCancel = () => {
        setIsModalShow(false)
    }
    
    const onInitialCancel = () => {
        setIsInitial(false)
    }

    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(officeList, query);
    }

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Data Entries" title="Energy Saving Measure" />

            { isMessageShow && <PopMessage onPopUpMessageClose={onPopUpMessageClose} msg={popUpMsg} /> }
            
            {
                isModalShow && <ModalPage 
                                    energySavingMeasureQuestion={energySavingMeasureQuestion} 
                                    emsRow={emsRow} 
                                    emsCol={emsCol}
                                    month={esmData.month}
                                    year={esmData.year}
                                    onHandleChange={onHandleChange} 
                                    esmData={esmData.day[emsRow-1][emsCol-1]} 
                                    onCancel={onCancel}
                                    /> 
            }
            
            {
                isInitial && <ConfirmModal 
                                msg={"Initial Personnel"}
                                col={initialCol}
                                month={esmData.month}
                                year={esmData.year}
                                onInitialCancel={onInitialCancel}
                                />
            }

            {
                cookies['__info'].role === 1 &&
                <div className="flex mt-2">
                    <div className="flex-1"></div>
                    <div className="flex-1 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                        <DropDownListComponent style={{fontSize: "15px"}} name="office_id" id="office_id" onChange={onHandleChange} dataSource={officeList} fields={office_fields} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a office' allowFiltering={true} placeholder="Select a office" />   
                    </div>
                    <div className="flex-1"></div>
                </div>
            }

            <div className="flex mt-2">
                <div className="flex-1"></div>
                <div className="flex-1 text-center">
                    <h2> DAILY MONITORING CHECKLIST </h2>
                    <h2> ENERGY SAVING MEASURE </h2>
                </div>
                <div className="flex-1"></div>
            </div>
            <div className="flex flex-row space-x-1 mt-2 justify-center">
                <div className="mt-2"> For the month of </div>
                <div>
                    <select id="month" name="month" onChange={onHandleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0">Select a month</option>
                        {
                            MonthsName.map((result, index) => {
                                return (
                                    <option value={result.id} key={index}> {result.name} </option>
                                )
                            })
                        }
                    </select>
                </div>
                <div>
                    <select id="year" name="year" onChange={onHandleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0">Select a year</option>
                        {
                            Years.map((result, index) => {
                                return (
                                    <option value={result} key={index}> {result} </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>

            <br></br>

            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-sm text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" rowSpan={3} colSpan={2} className="px-3 py-2 text-center border border-slate-400">
                                
                            </th>
                            <th scope="col" colSpan={31} className="px-3 py-2 text-center border border-slate-400">
                                Date and Time of Inspection/Monitoring   
                            </th>
                            <th scope="col" rowSpan={2} className="text-center border border-slate-400">
                                TO<br></br>TAL  
                            </th>
                        </tr>
                        <tr>    
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                1
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                2
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                3
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                4
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                5
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                6
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                7
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                8
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                9
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                10
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                11
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                12
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                13
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                14
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                15
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                16
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                17
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                18
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                19
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                20
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                21
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                22
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                23
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                24
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                25
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                26
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                27
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                28
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                29
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                30
                            </th>
                            <th scope="col" width={30} className="px-1 py-1 text-center border border-slate-400">
                                31
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            energySavingMeasureQuestion.map((result, index) => {
                                return (
                                    <tr key={index}> 
                                        <td scope="col" width={10} className="px-1 py-1 text-center border border-slate-400">
                                            { result.id }
                                        </td>
                                        <td scope="col" width={550} className="px-1 py-1 text-left border border-slate-400">
                                            { result.name }
                                        </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 1) }> <center>{ esmData.day.length > 0 && esmData.day[index][0] !== "" ? (esmData.day[index][0] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 2) }> <center>{ esmData.day.length > 0 && esmData.day[index][1] !== "" ? (esmData.day[index][1] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 3) }> <center>{ esmData.day.length > 0 && esmData.day[index][2] !== "" ? (esmData.day[index][2] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 4) }> <center>{ esmData.day.length > 0 && esmData.day[index][3] !== "" ? (esmData.day[index][3] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 5) }> <center>{ esmData.day.length > 0 && esmData.day[index][4] !== "" ? (esmData.day[index][4] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 6) }> <center>{ esmData.day.length > 0 && esmData.day[index][5] !== "" ? (esmData.day[index][5] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 7) }> <center>{ esmData.day.length > 0 && esmData.day[index][6] !== "" ? (esmData.day[index][6] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 8) }> <center>{ esmData.day.length > 0 && esmData.day[index][7] !== "" ? (esmData.day[index][7] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 9) }> <center>{ esmData.day.length > 0 && esmData.day[index][8] !== "" ? (esmData.day[index][8] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 10) }> <center>{ esmData.day.length > 0 && esmData.day[index][9] !== "" ? (esmData.day[index][9] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 11) }> <center>{ esmData.day.length > 0 && esmData.day[index][10] !== "" ? (esmData.day[index][10] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 12) }> <center>{ esmData.day.length > 0 && esmData.day[index][11] !== "" ? (esmData.day[index][11] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 13) }> <center>{ esmData.day.length > 0 && esmData.day[index][12] !== "" ? (esmData.day[index][12] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 14) }> <center>{ esmData.day.length > 0 && esmData.day[index][13] !== "" ? (esmData.day[index][13] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 15) }> <center>{ esmData.day.length > 0 && esmData.day[index][14] !== "" ? (esmData.day[index][14] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 16) }> <center>{ esmData.day.length > 0 && esmData.day[index][15] !== "" ? (esmData.day[index][15] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 17) }> <center>{ esmData.day.length > 0 && esmData.day[index][16] !== "" ? (esmData.day[index][16] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 18) }> <center>{ esmData.day.length > 0 && esmData.day[index][17] !== "" ? (esmData.day[index][17] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 19) }> <center>{ esmData.day.length > 0 && esmData.day[index][18] !== "" ? (esmData.day[index][18] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 20) }> <center>{ esmData.day.length > 0 && esmData.day[index][19] !== "" ? (esmData.day[index][19] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 21) }> <center>{ esmData.day.length > 0 && esmData.day[index][20] !== "" ? (esmData.day[index][20] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 22) }> <center>{ esmData.day.length > 0 && esmData.day[index][21] !== "" ? (esmData.day[index][21] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 23) }> <center>{ esmData.day.length > 0 && esmData.day[index][22] !== "" ? (esmData.day[index][22] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 24) }> <center>{ esmData.day.length > 0 && esmData.day[index][23] !== "" ? (esmData.day[index][23] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 25) }> <center>{ esmData.day.length > 0 && esmData.day[index][24] !== "" ? (esmData.day[index][24] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 26) }> <center>{ esmData.day.length > 0 && esmData.day[index][25] !== "" ? (esmData.day[index][25] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 27) }> <center>{ esmData.day.length > 0 && esmData.day[index][26] !== "" ? (esmData.day[index][26] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 28) }> <center>{ esmData.day.length > 0 && esmData.day[index][27] !== "" ? (esmData.day[index][27] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 29) }> <center>{ esmData.day.length > 0 && esmData.day[index][28] !== "" ? (esmData.day[index][28] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 30) }> <center>{ esmData.day.length > 0 && esmData.day[index][29] !== "" ? (esmData.day[index][29] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="px-1 py-1 border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(result.id, 31) }> <center>{ esmData.day.length > 0 && esmData.day[index][30] !== "" ? (esmData.day[index][30] === 1 ? <FaCheck/> : <TiDelete/>) : '' }</center> </td>
                                        <td scope="col" className="text-center border border-slate-400 font-bold"> { esmData.total_r.length > 0 && (esmData.total_r[index] > 0 ? esmData.total_r[index] : '') } </td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td scope="col" colSpan={2} className="px-1 py-1 text-center border border-slate-400 font-bold"> Total </td>  
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                            <td scope="col" className="px-1 py-1 border border-slate-400"></td>
                        </tr>
                    </tbody>
                </table>

                <table className="text-sm text-left rtl:text-right text-black-500 dark:text-black-400 mt-5 mb-5">
                    <tbody>
                        <tr>
                            <td scope="col" colSpan={2} width={80} className="px-1 py-1 border border-slate-400 font-bold text-center">Interpret the score with score intervals</td>
                        </tr>
                        <tr>
                            <td scope="col" width={80} className="px-1 py-1 border border-slate-400 bg-rose-400 text-center">0-5</td>
                            <td scope="col" width={250} className="px-1 py-1 border border-slate-400 bg-rose-400">Non Compliant</td>
                        </tr>
                        <tr>
                            <td scope="col" className="px-1 py-1 border border-slate-400 bg-orange-400 text-center">6-11</td>
                            <td scope="col" className="px-1 py-1 border border-slate-400 bg-orange-400">Partially -Compliant</td>
                        </tr>
                        <tr>
                            <td scope="col" className="px-1 py-1 border border-slate-400 bg-yellow-200 text-center">12-17</td>
                            <td scope="col" className="px-1 py-1 border border-slate-400 bg-yellow-200">Compliant</td>
                        </tr>
                        <tr>
                            <td scope="col" className="px-1 py-1 border border-slate-400 bg-green-400 text-center">18-23</td>
                            <td scope="col" className="px-1 py-1 border border-slate-400 bg-green-400">Highly Compliant</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EnergySavingMeasure