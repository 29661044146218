import React from 'react'
import iclose from "../data/close.png";
import iupload from "../data/upload.png";

const ModalUpload = props => {
    const {
        onCancelUpload,
        onReadingCSV,
        handleFileChange
    } = props

    return (
        <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                <div className="">
                    <div className="text-center p-4 flex-auto justify-center">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file">Upload file</label>
                        <input onChange={handleFileChange} accept=".csv" className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file" name="file" type="file" />
                    </div>
                    <div className="p-2 text-center space-x-4 md:block">
                        <button name="close" id="close" onClick={onReadingCSV} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-8 mr-3" src={iupload} alt=""/>
                            <span>Upload</span>
                        </button>
                        <button name="close" id="close" onClick={onCancelUpload} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-8 mr-3" src={iclose} alt=""/>
                            <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalUpload