import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from 'axios'

import { Header } from "../components";
import { BsPencilSquare } from 'react-icons/bs';

import ConsumptionModal from "./ConsumptionModal";

const MeterDetails = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [cookies] = useCookies()
    const [isEnergyOn, setIsEnergyOn] = useState([])
    const [isModalShow, setIsModalShow] = useState(false)
    const [buildingList, setBuildingState] = useState([])
    const [waterBuildingList, setWaterBuildingState] = useState([])
    const [meterNumber, setMeterNumberState] = useState([])
    const [meterData, setMeterDataState] = useState({
        office_id: cookies['__info'].office.id,
        building_id: ''
    })

    useEffect(() => {
        showBuildingList()
        showWaterBuildingList()
        showEnergyWaterMeterNumber()
    }, [])

    const showBuildingList = () => {
        api.get('get/building/' + cookies['__info'].campus_id)
        .then(res => {
            setBuildingState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showWaterBuildingList = () => {
        api.get('get/water-building/' + cookies['__info'].campus_id)
        .then(res => {
            setWaterBuildingState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    const showEnergyWaterMeterNumber = () => {
        api.get('get/meter-number/' + cookies['__info'].office.id)
        .then(res => {
            setMeterNumberState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const AddDataEntry = (val) => () => {
        if (val === 1) {
            setIsEnergyOn(true)
        } else if (val === 2) {
            setIsEnergyOn(false)
        }

        setIsModalShow(true)
    }

    const onHandleChange = (e) => {
        const {value} = e.target

        setMeterDataState({...meterData, building_id: value})
    }

    const saveEnergyMeter = async (params) => {
        let res = await api.post('post/add-energy-meter', {params})

        if (res.status === 200) {
            if (res.data.is_success === 1) {
                alert("Energy Meter Number has been added!")
                showEnergyWaterMeterNumber()
            } else if (res.data.is_success === 0) {
                alert("Error while updating record, Please try again")
            }

            setIsModalShow(false)
        }
    }

    const saveWaterMeter = async (params) => {
        let res = await api.post('post/add-water-meter', {params})

        if (res.status === 200) {
            if (res.data.is_success === 1) {
                alert("Water Meter Number has been added!")
                showEnergyWaterMeterNumber()
            } else if (res.data.is_success === 0) {
                alert("Water while updating record, Please try again")
            }

            setIsModalShow(false)
        }
    }

    const onSumbit = () => {
        if (isEnergyOn) {
            saveEnergyMeter(meterData)
        } else {
            saveWaterMeter(meterData)
        }
    }
    
    const onCancel = () => {
        setIsModalShow(false)
        setIsEnergyOn([])
    }

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Pages" title="Meter Details" />

            {
                isModalShow && <ConsumptionModal
                                buildingList={isEnergyOn ? buildingList : waterBuildingList}
                                onSumbit={onSumbit}
                                onHandleChange={onHandleChange} 
                                onCancel={onCancel}
                                /> 
            }

            <div className="overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-sm text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                        <tr>    
                            <th scope="col" width={30} className="px-2 py-4 text-center border border-slate-400">
                                ENERGY METER NUMBER
                            </th>
                            <th scope="col" width={30} className="px-2 py-4 text-center border border-slate-400">
                                WATER WATER NUMBER
                            </th> 
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col" className="px-2 py-4 text-center border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(1) }> 
                                <div className='flex justify-center'> { Object.keys(meterNumber).length > 0 ? (meterNumber[0].getbuilding !== null ? meterNumber[0].getbuilding.meter_no : <BsPencilSquare />) : <BsPencilSquare /> }  </div>
                            </td>
                            <td scope="col" className="px-2 py-4 text-center border border-slate-400 cursor-pointer hover:bg-green-500" onClick={ AddDataEntry(2) }>
                                <div className='flex justify-center'> { Object.keys(meterNumber).length > 0 ? (meterNumber[0].getbuildingwater !== null ? meterNumber[0].getbuildingwater.meter_no : <BsPencilSquare />) : <BsPencilSquare /> } </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MeterDetails