import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'

import { Button } from ".";
import { userProfileData } from "../data/list";
import avatar from "../data/user.png";
import ilogout from "../data/logout.png";

const UserProfile = () => {
  const [cookies, removeCookie] = useCookies()

  const onLogout = () => {
    removeCookie('__access_token');
    removeCookie('__info');

    window.location.href = "../login"
  }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {" "}
            { cookies['__info'].name + " " }
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            Administrator{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            { cookies['__info'].email + " " }
          </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {item.desc}{" "}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-5">
        <button onClick={onLogout} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
            <img className="h-8 mr-3" src={ilogout} alt=""/>
            <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
