import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from 'axios'
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Search, Page } from '@syncfusion/ej2-react-grids';

import { Header } from "../components";

const WaterBuilding = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [cookies] = useCookies()
    const [waterBuildingList, setWaterBuildingState] = useState([])

    useEffect(() => {
        showWaterBuildingList()
    }, [])
    
    const showWaterBuildingList = () => {
        api.get('get/water-building/' + cookies['__info'].campus_id)
        .then(res => {
            setWaterBuildingState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Pages" title="Water Consumption: Water Meter per Building" />
            <GridComponent 
            dataSource={waterBuildingList}
            width="auto"
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='name' headerText='Name of Office' />
                    <ColumnDirective field='meter_no' headerText='Water Meter No.' textAlign="Center" />
                    <ColumnDirective field='getcampus.campus_name' headerText='Campus' textAlign="Center" />
                </ColumnsDirective>
                <Inject services={[Search, Page]} />

            </GridComponent>
        </div>
    )
}

export default WaterBuilding