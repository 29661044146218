import React from 'react';

const Footer = () => (
  <div className="mt-5 mb-5">
    <p className="text-sm dark:text-gray-200 text-gray-700 text-center px-4">
      © 2024 Information and Communication Technologies Office (Pili)
    </p>
  </div>
);

export default Footer;