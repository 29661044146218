import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from 'axios'
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Search, Page } from '@syncfusion/ej2-react-grids';

import { Header } from "../components";

const Office = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [cookies] = useCookies()
    const [officeList, setOfficeState] = useState([])

    useEffect(() => {
        showOfficeList()
    }, [])
    
    const showOfficeList = () => {
        api.get('get/office/' + cookies['__info'].campus_id)
        .then(res => {
            setOfficeState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Pages" title="Office Water and Energy Meter Detail" />
            <GridComponent 
            dataSource={officeList}
            width="auto"
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='name' headerText='Name of Office' />
                    <ColumnDirective field='getemail.email' headerText='Email' />
                    <ColumnDirective field='getbuilding.name' headerText='Building' />
                    <ColumnDirective field='getbuilding.meter_no' headerText='Electric Meter No.' textAlign="Center" />
                    <ColumnDirective field='getbuildingwater.meter_no' headerText='Water Meter No.' textAlign="Center" />
                </ColumnsDirective>
                <Inject services={[Search, Page]} />

            </GridComponent>
        </div>
    )
}

export default Office;