import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useCookies } from "react-cookie";

import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Home,
  Office,
  Campus,
  Login,
  PageNotFound,
  EnergySavingMeasure,
  OtherResources,
  SolidWasteManagement,
  Building,
  EnergyConsumption,
  WaterConsumption,
  MeterDetails,
  WaterBuilding
} from "./pages";

import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";

const App = () => {
  const [cookies] = useCookies()

  const {
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>

        {
          cookies['__access_token'] && cookies['__access_token'] !== 'undefined'
          ?
            <div className="relative flex dark:bg-main-dark-bg">
              <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
                <TooltipComponent content="Settings" position="Top">
                  <button
                    type="button"
                    onClick={() => setThemeSettings(true)}
                    style={{ background: currentColor, borderRadius: "50%" }}
                    className="p-3 text-3xl text-white hover:drop-shadow-xl hover:bg-light-gray"
                  >
                    <FiSettings />
                  </button>
                </TooltipComponent>
              </div>
              {activeMenu ? (
                <div className="fixed bg-white w-72 sidebar dark:bg-secondary-dark-bg ">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )}
              <div
                className={
                  activeMenu
                    ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                    : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
                }
              >
                <div className="fixed w-full md:static bg-main-bg dark:bg-main-dark-bg navbar ">
                  <Navbar />
                </div>
                <div>
                  {themeSettings && <ThemeSettings />}

                  <Routes>
                    {/* dashboard  */}
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />

                    {/* pages  */}
                    { cookies['__info'].role === 1  && <Route path="/office" element={<Office />} /> }
                    { cookies['__info'].role === 1  && <Route path="/campus" element={<Campus />} /> }
                    { cookies['__info'].role === 1  && <Route path="/energy-meter" element={<Building />} /> }
                    { cookies['__info'].role === 1  && <Route path="/water-meter" element={<WaterBuilding />} /> }
                    
                    {/* data entries  */}
                    <Route path="/energy-saving" element={<EnergySavingMeasure />} />
                    <Route path="/other-resource" element={<OtherResources />} />
                    { cookies['__info'].role !== 2 && <Route path="/solid-waste-mgt" element={<SolidWasteManagement />} />}
                    
                    {/** Consumption **/}
                    <Route path="/energy" element={<EnergyConsumption />} />
                    <Route path="/water" element={<WaterConsumption />} />

                    { cookies['__info'].role === 2  && <Route path="/meter-details" element={<MeterDetails />} /> }

                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </div>
                <Footer />
              </div>
            </div>
          :
          <div>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Footer />
          </div>
        }
      </BrowserRouter>
    </div>
  );
};

export default App;