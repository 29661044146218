import React from 'react'
import icancel from "../data/cancel.png";
import icheck from "../data/check.png";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';

const ConsumptionModal = props => {
    const {
        buildingList,
        onSumbit,
        onHandleChange,
        onCancel,
    } = props

    const building_fields = { text: 'name', value: 'id' };

    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(buildingList, query);
    }

    return (
        <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                <div className="">
                    <div className="text-center p-4 flex-auto justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-11 h-6 -m-1 flex items-center text-blue-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        
                        <h2 className="text-base font-bold mt-4"> Please select building located your office! </h2>
                    </div>
                    <div className="flex text-center p-1 mb-5 flex-auto justify-center">
                        <div className="flex-1 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                            <DropDownListComponent style={{fontSize: "15px"}} name="building_id" id="building_id" onChange={onHandleChange} dataSource={buildingList} fields={building_fields} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a Building' allowFiltering={true} placeholder="Select a Building" />   
                        </div>
                    </div>
                    <div className="p-2 text-center space-x-4 md:block">
                        <button name="cancel" id="cancel" onClick={onSumbit} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-7 mr-1" src={icheck} alt=""/> Save
                        </button>
                        <button name="cancel" id="cancel" onClick={onCancel} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-7 mr-1" src={icancel} alt=""/> Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsumptionModal