import React, { useState, useEffect } from "react";
import axios from 'axios'
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Search, Page } from '@syncfusion/ej2-react-grids';

import { Header } from "../components";

const Campus = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [campusList, setCampusState] = useState([])
    
    useEffect(() => {
        showCampusList()
    }, [])
    
    const showCampusList = () => {
        api.get('get/campus')
        .then(res => {
            setCampusState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }
  
    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Pages" title="Campus" />
            <GridComponent 
            dataSource={campusList}
            width="auto"
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' headerText='No.' width='80' textAlign='Center' />
                    <ColumnDirective field='campus_name' headerText='Name of Campus' />
                </ColumnsDirective>
                <Inject services={[Search, Page]} />

            </GridComponent>
        </div>
    )
}

export default Campus;