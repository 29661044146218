import React, {useState} from "react";
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useCookies } from "react-cookie";

import lgoogle from "../data/logo-google.png";

const Login = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: true
    })

    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies(['__access_token', '__info'])

    const [loginState, setLoginState] = useState( {
        username: '',
        password: ''
    })

    const onHandleChange = (e) => {
        const {name, value} = e.target
        setLoginState({...loginState, [name]: value})
    }
    
    const onHandleClickLogin = () => {
        let params = {
            'email': loginState.username,
            'password': loginState.password,
        }

        onLogin(params)
    }
    
    const onLogin = async (params) => {
        let res = await api.post('post/login', { data: params })

        if (res.status === 200) {
            if (!res.data.error) {
                setCookie('__info', res.data.credentials.info)
                setCookie('__access_token', res.data.credentials.access_token)

                navigate('/home', { replace: true });
            } else {
                alert("Invalid credentials, Please try again.")
            }
        }
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            onHandleClickLogin()
        }
    }

    return (
        <div className="flex items-center justify-center h-screen dark:bg-main-dark-bg">
            <div className="w-full max-w-xl bg-white border border-gray-200 rounded-lg shadow-md p-6 mt-3">
                <div className="flex items-center justify-center mb-10 -mt-1">
                    <div className=" flex w-full max-w-xl justify-center">
                        <label className="inline-flex items-center bg-white/30 px-4 py-1">
                            <h1 className="mb-1 text-center text-lg sm:text-2xl font-extrabold text-gray-900 dark:text-white"><span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">ADMIN LOGIN</span></h1>
                        </label>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor='username'>Email address</label>
                        <input onChange={onHandleChange} autoComplete="username" name="username" id="username" className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none" type='email'  required />
                    </div>
                    <div className="w-full md:w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor='password'>Password</label>
                        <input onChange={onHandleChange}  onKeyPress={handleKeyPress} name="password" id="password" className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none" type='password' required />
                    </div>
                    <div className="w-full flex items-center justify-between px-3 mb-3 ">
                        <label htmlFor="remember" className="flex items-center w-1/2">
                            <input type="checkbox" name="remember" id="remember" className="mr-1 bg-white shadow" />
                            <span className="text-sm text-gray-700 pt-1">Remember Me</span>
                        </label>
                        <div className="w-1/2 text-right">
                            <a href="#" className="text-blue-500 text-sm tracking-tight">Forget your password?</a>
                        </div>
                    </div>
                    <div className="w-full md:w-full px-3">
                        <button onClick={onHandleClickLogin} className="appearance-none block w-full bg-blue-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none focus:bg-white focus:border-gray-500">Sign in</button>
                    </div>
                    <div className="mx-auto -mb-6 pb-1">
                        <span className="text-center text-xs text-gray-700">or sign in with</span>
                    </div>
                    <div className="flex items-center justify-center w-full mt-4">
                        <div className="w-full md:w-1/3 px-3 pt-4 mx-2">
                            <a className="flex items-center justify-center w-full py-4 text-sm font-medium transition duration-300 rounded-2xl text-grey-900 bg-grey-300 hover:bg-grey-400 focus:ring-4 focus:ring-grey-300">
                                <img className="h-5 mr-2" src={lgoogle} alt=""/>
                                Sign in with Google
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login