import React from 'react'
import iclose from "../data/close.png";


const PopMessage = props => {
    const {
        onPopUpMessageClose,
        msg
    } = props

    return (
        <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                <div className="">
                    <div className="text-center p-4 flex-auto justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-11 h-6 -m-1 flex items-center text-blue-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </div>
                    <div className="p-4 mb-5 text-sm text-center text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                        <span className="font-medium"> { msg } </span>
                    </div>

                    <div className="p-2 text-center space-x-4 md:block">
                        <button name="close" id="close" onClick={onPopUpMessageClose} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-8 mr-3" src={iclose} alt=""/>
                            <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopMessage