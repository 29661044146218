import React from 'react';
import { FiHome } from 'react-icons/fi';
import { BsFillInfoSquareFill, BsFillInboxFill, BsCardChecklist, BsBuilding, BsWater } from 'react-icons/bs';
import { IoIosSchool, IoIosHome, IoIosTrash, IoIosWater, IoIosFlash, IoMdSpeedometer, IoIosPerson } from 'react-icons/io';

export var Years = []

const showYears = () => {
  for (var i = new Date().getFullYear(); i >= 2010; i--) {
    Years.push(i)
  }
}

showYears()

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
)

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
)

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'home',
        label: 'Home',
        icon: <FiHome />,
      },
    ],
  },
  {
    title: 'Data Entries',
    links: [
      {
        name: 'energy-saving',
        label: 'Energy Saving Measure',
        icon: <IoIosHome />,
      },
      {
        name: 'other-resource',
        label: 'Other Resources',
        icon: <IoIosSchool />,
      },
      {
        name: 'solid-waste-mgt',
        label: 'Solid Waste Management',
        icon: <IoIosTrash />,
      },
    ],
  },
  {
    title: 'Pages',
    links: [
      {
        name: 'energy-meter',
        label: 'Energy Meter',
        icon: <BsBuilding />,
      },
      {
        name: 'water-meter',
        label: 'Water Meter',
        icon: <BsWater />,
      },
      {
        name: 'office',
        label: 'Office',
        icon: <IoIosHome />,
      },
      {
        name: 'campus',
        label: 'Campus',
        icon: <IoIosSchool />,
      },
    ],
  },
  {
    title: 'Consumption',
    links: [
      {
        name: 'energy',
        label: 'Energy Consumption',
        icon: <IoIosFlash />,
      },
      {
        name: 'water',
        label: 'Water Consumption',
        icon: <IoIosWater />,
      },
    ],
  },
  {
    title: 'My Account',
    links: [
      {
        name: 'meter-details',
        label: 'Meter Details',
        icon: <IoMdSpeedometer />,
      },
      // {
      //   name: 'profile',
      //   label: 'Profile',
      //   icon: <IoIosPerson />,
      // },
    ],
  },
  {
    title: 'Reports',
    links: [],
  }
]

export const chatData = [
 
]

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
]

export const userProfileData = [
  {
    icon: <BsFillInfoSquareFill />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsFillInboxFill />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <BsCardChecklist />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  },
]

/*******  ADDITIONAL  ********/

export const sexList = [
  {id: 'F', name: 'Female'},
  {id: 'M', name: 'Male'},
  {id: 'L', name: 'LGBTQIA+'}
]

export const ageList = [
  {id: '19 or lower', name: '19 or lower'},
  {id: '20-34', name: '20-34'},
  {id: '35-49', name: '35-49'},
  {id: '50-64', name: '50-64'},
  {id: '65 or higher', name: '65 or higher'},
  {id: 'did not specify', name: 'did not specify'}
]

export const introText = [
  {
    en: {
      title: "HELP US SERVE YOU BETTER!",
      desc: "This Client Satisfaction Survey (CSS) tracks the customer experience of CBSUA offices. Your feedback on your recently concluded transaction will help this office provide a better service. Personal information shared will be kept confidential and you always have the option to not answer this form.",
      button: "Let's Start"
    },
    fil: {
      title: "TULUNGAN MO KAMI MAS MAPABUTI ANG AMING MGA PROSESO AT SERBISYO!",
      desc: "Ang Client Satisfaction Survey (CSS) ay naglalayong masubaybayan ang karanasan ng taumbayan hinggil sa kanilang pakikitransaksyon sa mga tanggapan ng CBSUA. Makatutulong ang inyong kasagutan ukol sa inyong naging karanasan sa kakatapos lamang na transaksyon, upang mas mapabuti at lalong mapahusay ang aming serbisyo publiko. Ang personal na impormasyon na iyong ibabahagi ay mananatiling kumpidensyal. Maaari ring piliin na hindi sagutan ang sarbey na ito.",
      button: "Simulan Natin"
    }
  }
]

export const RaterInfoText = [
  {
    en: {
      client_type: "Client type",
      date: "Date",
      sex: "Sex",
      location: "City/Mun.&Province of residence",
      age: "Age",
      service_avail: "Service Availed",
      intro: "INSTRUCTIONS",
      cc_guide: "Select your answer to the Citizen's Charter (CC) questions. The Citizen's Charter is an official document that reflects the services of a government agency/office including its requirements, fees, and processing times among others.",
      sqd_guide: "For SQD 0-8, please select your answer on the column that best corresponds to your answer."
    },
    fil: {
      client_type: "Uri ng Kliyente",
      date: "Petsa",
      sex: "Kasarian",
      location: "Syudad/Munisipyo & Probinsya",
      age: "Edad",
      service_avail: "Uri ng transaksyon o serbisyo",
      intro: "PANUTO",
      cc_guide: "Piliin ang iyong sagot sa mga sumusunod na katanungan tungkol sa Citizen’s Charter (CC). Ito ay isang opisyal na dokumento na naglalaman ng mga serbisyo sa isang ahensya/opisina ng gobyerno, makikita rito ang mga kinakailangan na dokumento, kaukulang bayarin, at pangkabuuang oras ng pagproseso.",
      sqd_guide: "Para sa SQD 0-8, piliin sa hanay na pinakaangkop sa iyong sagot."
    }
  }
]

export const categoryList = [
  {id: '1', name: 'Age'},
  {id: '2', name: 'Sex'},
  {id: '3', name: 'Offices'},
  {id: '4', name: 'Rater'},
]

export const categoryList2 = [
  {id: '1', name: 'Citizens Charter'},
  {id: '2', name: 'Service Quality Dimension'}
]

export const energySavingMeasureQuestion = [
  { id: 1, name: 'Air conditioning unit/s are turned on from 9:00am to 4:00pm'},
  { id: 2, name: 'Observance of "one-day a week aircon holiday"'},
  { id: 3, name: 'Observance of earth hour, at 12:00nn to 1pm(1st week-Monday, 2nd Week-Tuesday, 3rd week-Wednesday and 4th week-Thursday)'},
  { id: 4, name: 'Lights are turned off before leaving the office'},
  { id: 5, name: 'Electric Fan/s are turned off when not in use'},
  { id: 6, name: 'Electrical Water dispenser/s are unplugged before leaving the office'},
  { id: 7, name: 'Computers and monitors are turned off at the end of the work day'},
  { id: 8, name: 'Printers and copiers are turned off when not in use'},
  { id: 9, name: 'No playing of computer games.'},
  { id: 10, name: 'No staying in the office between 6:00pm to 7:00am, unless allowed.'},
]

export const otherResourcesQuestion = [
  { id: 1, name: 'Number of pieces of paper used' },
  { id: 2, name: 'Number of pieces of properly use paper' },
  { id: 3, name: 'Number of pieces of paper with one side clean of print or marks available for reuse' },
  { id: 4, name: 'Number of pieces of paper reused/recycled' },
  { id: 5, name: 'Number of spoilage (pieces of paper) due to error' },
  { id: 6, name: 'Number of documents send through online' },
]

export const solidWasteMgtQuestion = [
  { id: 1, name: 'With separate bin for each type of waste' },
  { id: 2, name: 'Waste are properly segregated' },
  { id: 3, name: 'Waste are properly stored before pickup' },
  { id: 4, name: 'Correct trash bins are placed outside the office on the scheduled collection days' },
  { id: 5, name: 'Hazardous waste are properly stored' },
]

export const MonthsName = [
  { id: 1, name: 'January' },
  { id: 2, name: 'Feburary' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },  
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
]